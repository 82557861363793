<template>
  <v-container grid-list-md>
    <v-layout justify-center class="login">
      <v-flex>
        <v-scale-transition>
          <v-card class="pa-8 elevation-6 ma-auto" max-width="400" justify-center>
            <v-img max-width="300" src="../assets/logo_mpass.png" style="margin: auto"></v-img>

            <v-form ref="form" lazy-validation @keyup.native.enter="submit">
              <v-container mt-2>
                <v-alert dense outlined type="error" icon="warning" :value="errorSummary">
                  {{ errorSummary }}
                </v-alert>
                <v-layout column>
                  <v-text-field
                    class="my-4"
                    label="Email"
                    v-model="user.email"
                    solo
                    hide-details="auto"
                    prepend-icon=""
                  >
                    <v-icon slot="append"> mdi-account</v-icon>
                  </v-text-field>

                  <v-text-field
                    class="my-4"
                    label="Contraseña"
                    v-model="user.password"
                    type="password"
                    solo
                    hide-details="auto"
                  >
                    <v-icon slot="append"> mdi-lock</v-icon>
                  </v-text-field>

                  <v-checkbox
                    v-show="false"
                    label="Recordarme"
                    v-model="user.rememberMe"
                  ></v-checkbox>
                </v-layout>
              </v-container>
            </v-form>
            <v-card-actions class="text-center">
              <v-flex>
                <v-btn color="accent" block @click="submit" :loading="loading">ACCEDER</v-btn>
              </v-flex>
            </v-card-actions>
          </v-card>
        </v-scale-transition>
      </v-flex>
    </v-layout>
    <label class="version grey--text">MPASS v.{{ version }}</label>
  </v-container>
</template>

<script>
const shared = require("../shared/validation");

export default {
  name: "Login",
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      emailRules: shared.emailRules,
      passwordRules: shared.passwordRules,
      summaryText: null,
    };
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    version() {
      return process.env.VUE_APP_VERSION;
    },
    errorSummary() {
      return this.summaryText;
    },
  },
  methods: {
    async submit() {
      var form = this.$refs.form;
      if (!form.validate()) {
        this.summaryText = "Corrija los errores";
      } else {
        this.summaryText = null;
        this.$store
          .dispatch("login", {
            email: this.user.email,
            password: this.user.password,
            remeberMe: false,
          })
          .then(
            response => {
              this.user.password = "";
              this.user.email = "";
              this.$router.push(response);
            },
            error => {
              this.summaryText = error;
            }
          );
      }
    },
  },
};
</script>

<style scoped>
.version {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
}
</style>
